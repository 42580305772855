import {
  DatapointMap,
  TimeSeries,
  convertToTimeseries,
  datapointsToMap,
  generateIdealBinSize,
  useDataApi,
} from "api/data";
import { Thing } from "api/ingestion/things.ts";
import { MeterDetail, Pin, StatusAlert } from "components";
import MetricCard from "components/alerts/MetricCard";
import { RawChart } from "components/charts";
import { mapboxConfig } from "configs/mapbox";
import { ReactComponent as DetailsIcon } from "images/icons/deviceDetailTabs/details.svg";
import { ReactComponent as ControlsIcon } from "images/icons/deviceDetailTabs/controls.svg";
import { ReactComponent as SecurityIcon } from "images/icons/deviceDetailTabs/security.svg";

import SecurityTab from "./SecurityTab";
import { useSelectedSimulation } from "context/SelectedSimulationContext";
import { useSelectedTimeRange } from "context/SelectedTimeRangeContext.tsx";
import { typeToLabel } from "utils/typeToLabel";

import { useEffect, useState } from "react";
import Map, { Marker } from "react-map-gl";

import { useAuth } from "../../context/AuthContext";

enum TabType {
  DETAILS = "details",
  SECURITY = "security",
  CONTROLS = "controls",
}

const DeviceDetail = ({
  selectedDevice,
  setSelectedDevice = () => {},
  placeType,
}: {
  selectedDevice: Thing;
  setSelectedDevice?: (device: Thing | null) => void;
  placeType: string;
}) => {
  const isMeter = selectedDevice?.thingType == "Meter"; // TODO: this should be more robust

  return (
    <div
      className={`origin-right pr-6 h-screen overflow-y-auto bg-white lg:relative transition-transform drop-shadow-xl lg:drop-shadow-none ${
        selectedDevice
          ? "translate-x-0 lg:ml-4 px-2 right-0 scale-x-100 min-w-[500px] lg:w-2/5 z-20"
          : "pl-4 translate-x-full scale-x-0 w-0"
      }`}
    >
      {selectedDevice && !isMeter && (
        <ChargerOrBatteryDetails
          {...selectedDevice}
          placeType={placeType}
          setSelectedDevice={setSelectedDevice}
        />
      )}
      {selectedDevice && isMeter && (
        <MeterDetail
          {...selectedDevice}
          placeType={placeType}
          setSelectedDevice={setSelectedDevice}
        />
      )}
    </div>
  );
};

const ChargerOrBatteryDetails = ({
  placeType,
  siteId,
  thingId,
  thingType,
  thingName,
  longitude,
  latitude,
  model,
  setSelectedDevice,
}: {
  placeType: string;
  siteId: string;
  thingId: string;
  thingType: string;
  thingName: string;
  longitude: number;
  latitude: number;
  model: string;
  setSelectedDevice: (device: Thing | null) => void;
}) => {
  const isCharger = thingType == "Charger"; // TODO: this should be more robust
  const { start, end } = useSelectedTimeRange();
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState<TabType>(TabType.DETAILS);
  const [alerts, setAlerts] = useState([]);
  const [stats, setStats] = useState<DatapointMap>({});
  const [timeseries, setTimeseries] = useState<TimeSeries | null>(undefined);
  const { simulationId } = useSelectedSimulation();
  const { getDataForThing, getSummaryForThing, getAlertsForThing } =
    useDataApi();
  useEffect(() => {
    const fetchAlerts = async () => {
      const alerts = await getAlertsForThing(
        placeType ?? "site",
        siteId,
        thingId,
        start,
        end,
        simulationId,
      );
      setAlerts(alerts);
    };
    const fetchStats = async () => {
      const stats = await getSummaryForThing(
        placeType ?? "site",
        siteId,
        thingId,
        start,
        end,
        simulationId,
      );
      setStats(datapointsToMap(stats));
    };

    const fetchTimeseries = async () => {
      const [binValue, binUnit] = generateIdealBinSize(start, end);
      getDataForThing(
        placeType ?? "site",
        siteId,
        thingId,
        start,
        end,
        binUnit,
        binValue,
        simulationId,
      )
        .then(convertToTimeseries)
        .then(setTimeseries)
        .catch((e) => {
          setTimeseries(null);
          console.error(e);
        });
    };

    if (!user) return;

    fetchAlerts();
    fetchStats();
    fetchTimeseries();
  }, [placeType, siteId, thingId, start, end, isCharger, simulationId]);

  const closeButton = (
    <span
      onClick={() => setSelectedDevice(null)}
      className="text-blue50 float-right cursor-pointer"
    >
      &times;
    </span>
  );

  const statsView = (
    <div className="p-4 elevation-1 rounded-md flex flex-col gap-4">
      <h3 className="text-heading3 text-space50">Stats</h3>
      <div className="grid grid-cols-2 gap-4">
        {Object.values(stats).map((stat) => (
          <MetricCard
            key={stat.type}
            metricCardStat={{
              value: stat.value.toFixed(2) || null,
              label: typeToLabel(stat.type),
              units: stat.unit,
              trend: 0,
            }}
          />
        ))}
      </div>
    </div>
  );

  const alertsView = (
    <div className="p-4 elevation-1 rounded-md flex flex-col gap-4">
      <StatusAlert batteryAlerts={alerts} chargerAlerts={[]} />
    </div>
  );

  const map = (
    <div className="p-4 w-full elevation-1 rounded-md flex overflow-hidden h-[300px]">
      <Map
        initialViewState={{
          longitude,
          latitude,
          zoom: 12,
        }}
        viewState={{
          width: "100%",
          height: "100%",
        }}
        mapStyle="mapbox://styles/mapbox/light-v11"
        mapboxAccessToken={mapboxConfig.token}
      >
        <Marker longitude={longitude} latitude={latitude} anchor="center">
          <Pin color={"#5EA15F"} id={thingName.slice(-1)} />
        </Marker>
      </Map>
    </div>
  );

  const utilizationChart = (
    <div className="p-4 elevation-1 rounded-md flex flex-col gap-4">
      <h3 className="text-heading3 text-space50">Utilization Chart</h3>
      <RawChart timeseries={timeseries} colors={["#f2aa3c", "#ab5fb3"]} />
    </div>
  );

  const rawDetails = (
    <div className="p-4 elevation-1 rounded-md flex flex-col">
      <h3 className="text-heading3 text-space50 mb-4">Raw Details</h3>
      <p className="text-caption text-space70">Site: {siteId}</p>
      <p className="text-caption text-space70">
        Location: {latitude}, {longitude}
      </p>
      <p className="text-caption text-space70">Type Name: {thingType}</p>
      <p className="text-caption text-space70">Name: {thingName}</p>
      <p className="text-caption text-space70">Model: {model}</p>
    </div>
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case TabType.DETAILS:
        return (
          <div className="flex flex-col gap-4">
            {statsView}
            {alertsView}
            {map}
            {utilizationChart}
            {rawDetails}
          </div>
        );
      case TabType.SECURITY:
          return (
            <SecurityTab
              placeType={placeType}
              siteId={siteId}
              thingId={thingId}
            />
          );
      case TabType.CONTROLS:
        return (
          <div className="p-4 elevation-1 rounded-md">
            <h3 className="text-heading3 text-space50">Device Controls</h3>
            <p className="text-space70 mt-2">Control panel coming soon</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col gap-4 py-4">
      <div className="relative">
  <div className="items-center gap-2 text-gray-500">
    <p className="text-xs uppercase">{placeType}</p>
    <h4 className="text-lg font-medium text-gray-900">
      {thingType} #{thingName}
    </h4>
  </div>
  <button 
    onClick={() => setSelectedDevice(null)}
    className="absolute top-0 right-0 text-gray-500 hover:text-gray-700"
  >
    &times;
  </button>
</div>

<div className="rounded-lg p-1"> {/* Added container with gray background */}
  <nav className="grid grid-cols-3 gap-1">
    <button
      onClick={() => setActiveTab(TabType.DETAILS)}
      className={`flex flex-col items-center py-3 px-2 rounded-lg transition-colors ${
        activeTab === TabType.DETAILS
          ? "bg-slate-200 text-slate-600"
          : "text-gray-500 hover:text-gray-700 hover:bg-gray-50"
      }`}
    >
      <DetailsIcon className={`w-5 h-5 mb-1 ${
        activeTab === TabType.DETAILS ? "text-blue-600" : "text-gray-500"
      }`} />
      <span className="text-sm">Details</span>
    </button>
    <button
      onClick={() => setActiveTab(TabType.SECURITY)}
      className={`flex flex-col items-center py-3 px-2 rounded-lg transition-colors ${
        activeTab === TabType.SECURITY
          ? "bg-slate-200 text-slate-600"
          : "text-gray-500 hover:text-gray-700 hover:bg-gray-50"
      }`}
    >
      <SecurityIcon className={`w-5 h-5 mb-1 ${
        activeTab === TabType.SECURITY ? "text-blue-600" : "text-gray-500"
      }`} />
      <span className="text-sm">Security</span>
    </button>
    <button
      onClick={() => setActiveTab(TabType.CONTROLS)}
      className={`flex flex-col items-center py-3 px-2 rounded-lg transition-colors ${
        activeTab === TabType.CONTROLS
          ? "bg-slate-200 text-slate-600"
          : "text-gray-500 hover:text-gray-700 hover:bg-gray-50"
      }`}
    >
      <ControlsIcon className={`w-5 h-5 mb-1 ${
        activeTab === TabType.CONTROLS ? "text-blue-600" : "text-gray-500"
      }`} />
      <span className="text-sm">Controls</span>
    </button>
  </nav>
</div>

      <div className="mt-4">{renderTabContent()}</div>
    </div>
  );
};

export default DeviceDetail;
