import {
  Datapoint,
  DatapointMap,
  Timeseries,
  convertToTimeseries,
  datapointsToMap,
  generateIdealBinSize,
  useDataApi,
} from "api/data";
import { SiteResponse, usePlacesApi } from "api/ingestion/places.ts";
import {
  SimulationResponse,
  useSimulationsApi,
} from "api/ingestion/simulations.ts";
import { useThingsApi } from "api/ingestion/things.ts";
import {
  DeviceDetail,
  EnergyCard,
  NetworkMap,
  SkyportsChargerStatus,
  StatusAlert,
} from "components";
import { HorizontalTimeseriesChart } from "components/charts/horizontalTimeSeries.tsx";
import {
  SelectedDeviceProvider,
  useSelectedDevice,
} from "context/SelectedDeviceContext.tsx";
import {
  SelectedSimulationProvider,
  SelectedSimulationSelector,
  useSelectedSimulation,
} from "context/SelectedSimulationContext.tsx";
import {
  SelectedSiteLevelProvider,
  useSelectedSiteLevel,
} from "context/SelectedSiteLevelContext.tsx";
import {
  SelectedTimeRangeProvider,
  TimeRangeSelector,
  useSelectedTimeRange,
} from "context/SelectedTimeRangeContext.tsx";

import { useEffect, useState } from "react";
import { FaEdit, FaPlus } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";

import { useAuth } from "../../context/AuthContext.tsx";
import AddSiteModal from "../locationSelection/AddSiteModal";
import { EditSiteModal } from "./EditSiteModal";
import FilteredDevices from "./filteredDevices.tsx";
import { LevelGroupDetails } from "./levelGroups.tsx";
import { TopologyOverview } from "./topology.tsx";

const Tile = ({
  className = "",
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={`bg-white rounded-md shadow border border-gray90 ${className}`}
    >
      {children}
    </div>
  );
};

const DeviceDetailWrapper = () => {
  // allows the use of the selected device context
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  return (
    <DeviceDetail
      selectedDevice={selectedDevice}
      setSelectedDevice={setSelectedDevice}
      placeType="site"
    />
  );
};

const PageContent = ({ siteId }: { siteId: string }) => {
  const [site, setSite] = useState<SiteResponse | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const { simulationId, simulation } = useSelectedSimulation();

  const { user, permissions } = useAuth();
  const { editSite, getSiteDetail, addSite, deleteSite } = usePlacesApi();
  const { getSiteAggregateReport, getTimeseriesForSite, getSummaryForSite } =
    useDataApi();
  const { getThingsFromSite } = useThingsApi();

  // TODO: Hooks need to be cleaned up as well as all of data pulling
  const { start, end } = useSelectedTimeRange();

  const [batteryDevices, setBatteryDevices] = useState([]);
  const [chargerDevices, setChargerDevices] = useState([]);
  const [meterDevices, setMeterDevices] = useState([]);

  const [summaryStats, setSummaryStats] = useState<DatapointMap>({});

  const [isDownloadingAggregateData, setIsDownloadingAggregateData] =
    useState(false);

  const [allMeterTimeseries, setAllMeterTimeseries] =
    useState<Timeseries | null>(undefined);

  // TODO: Data pulling has no dependencies so needs to be seperated out
  useEffect(() => {
    if (!user) return;

    // Reset all timeseries
    if (simulationId === null) {
      setAllMeterTimeseries(undefined);
    }

    // Puling all events from API

    getSiteDetail(siteId)
      .then(setSite)
      .catch((err) => console.error(err));

    const [binValue, binUnit] = generateIdealBinSize(start, end);
    getTimeseriesForSite(siteId, start, end, binUnit, binValue, simulationId)
      .then(convertToTimeseries)
      .then(setAllMeterTimeseries)
      .catch(console.error);

    // TODO: this code should be re-evaluated
    getThingsFromSite(siteId)
      .then((devices) => {
        // group by device.thingType
        let groupedDevices = devices.reduce((acc, device) => {
          acc[device.thingType] = (acc[device.thingType] ?? []).concat(device);
          return acc;
        }, {});

        setBatteryDevices(groupedDevices.Battery ?? []);
        setChargerDevices(groupedDevices.Charger ?? []);
        setMeterDevices(groupedDevices.Meter ?? []);
      })
      .catch((err) => console.error(err));

    getSummaryForSite(siteId, start, end, simulationId)
      .then(datapointsToMap)
      .then(setSummaryStats)
      .catch(console.error);
  }, [start, end, siteId, user?.partnerId, simulationId]);

  // TODO: we should fix this
  const energyCardStats = [
    {
      value: summaryStats.net?.value.toFixed(0) ?? null,
      units: "kWh",
      label: "Drawn from Grid",
      trend: 0,
    },
    {
      value: null,
      units: "kWh",
      label: "Dispensed to Vehicles",
      trend: 0,
    },
    {
      value: null,
      units: "kWh",
      label: "Currently Stored",
      trend: 0,
    },
    {
      value: null,
      units: "kWh",
      label: "Forecasted remaining Demand",
      trend: 0,
    },
  ];

  const handleDownloadAggregateReport = async () => {
    setIsDownloadingAggregateData(true);
    try {
      await getSiteAggregateReport(siteId, start, end, "h", 1, simulationId);
    } catch (error) {
      alert(`Download failed: ${error}`);
      console.error("Download failed:", error);
    } finally {
      setIsDownloadingAggregateData(false);
    }
  };

  // Can flow from prev 3 stats combined
  // const finalNetData = netData.filter(point => parseFloat(point.x) > lowerHourBound && parseFloat(point.x) < upperHourBound);

  // TODO: Aggregate the energy data to calculate the energy card stats
  // No grid energy drawn in raw battery stats, aggregate may not be pulled from events at all

  // TODO: For this demo, we can also calculate the chargerStates from aggregated energy data, dispensed coming from finalUsageData and scheduled remaining from schedule

  // TODO: add alerts
  const alerts = <StatusAlert batteryAlerts={[]} chargerAlerts={[]} />;

  const stats = <EnergyCard energyCardStats={energyCardStats} />;

  const chargerStatus = (
    <SkyportsChargerStatus
      chargers={chargerDevices}
      batteries={batteryDevices}
      meters={meterDevices}
    />
  );

  const gridEnergyDraw = (
    <HorizontalTimeseriesChart timeseries={allMeterTimeseries} />
  );

  const handleSaveSite = async (formData) => {
    const result = await editSite(siteId, formData);
    if (result.success) {
      setSite({ ...site, ...formData });
      return true;
    } else {
      console.error("Failed to update site:", result.error);
      return false;
    }
  };

  const handleDeleteSite = async () => {
    try {
      const result = await deleteSite(siteId);
      if (result.success) {
        navigate("/sites"); // Redirect to the sites list page after successful deletion
      } else {
        console.error("Failed to delete site:", result.error);
        alert("Failed to delete site. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting site:", error);
      alert("An error occurred while deleting the site. Please try again.");
    }
  };

  const handleOpenAddModal = () => setIsAddModalOpen(true);
  const handleCloseAddModal = () => setIsAddModalOpen(false);

  const handleAddSite = async (siteData) => {
    try {
      const result = await addSite(siteData);
      if (result.success) {
        return {
          success: true,
          siteId: result.siteId,
        };
      } else {
        return { success: false, error: result.error };
      }
    } catch (error) {
      console.error("Error in handleAddSite:", error);
      return { success: false, error: { message: error.message } };
    }
  };

  const renderActionButtons = () => {
    if (permissions.includes("write:ingest_things")) {
      return (
        <div className="flex items-center gap-2">
          <button
            onClick={() => setIsEditModalOpen(true)}
            className="px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex border border-space80 hover:bg-gray95 text-space70"
          >
            <div className="text-xs">
              <FaEdit />
            </div>
            <div className="text-xs font-medium leading-[14px]">Edit Site</div>
          </button>
          <button
            className="px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex border border-space80 hover:bg-gray95 text-space70"
            onClick={handleOpenAddModal}
          >
            <div className="text-xs">
              <FaPlus />
            </div>
            <div className="text-xs font-medium leading-[14px]">Add Site</div>
          </button>
        </div>
      );
    }
    return null;
  };

  if (!site) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-full">
      <div className="grow h-screen overflow-y-scroll">
        <div className="flex pt-4 pb-3 justify-between">
          <div className="text-heading1 text-space50 flex items-center">
            <div className="flex items-center">
              <NavLink to="/sites">Sites</NavLink>
              <span className="mx-1">/</span>
              <span>{site.siteName}</span>
            </div>
            <div className="ml-4">{renderActionButtons()}</div>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={handleDownloadAggregateReport}
              disabled={isDownloadingAggregateData}
              className="px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex border border-space80 hover:bg-gray95 text-space70"
            >
              <div className="text-xs font-medium leading-[14px]">
                {isDownloadingAggregateData
                  ? "Downloading..."
                  : "Download Aggregate Report"}
              </div>
            </button>
            <SelectedSimulationSelector />
            <TimeRangeSelector />
          </div>
        </div>
        <hr />
        <div className="my-4">
          <h2 className="text-xl font-semibold">{site.siteName}</h2>
          <span className="text-gray-600">{site.address}</span>
          <br />
          <span className="text-gray-600">
            {site.latitude}, {site.longitude}
          </span>
        </div>

        {simulationId !== null && (
          <div className="my-4 bg-blue90 p-4 rounded-md">
            <p className="font-bold">Current Simulation: {simulationId}</p>
            <p>
              {simulation.startDate.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
              {" - "}
              {simulation.endDate?.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
          </div>
        )}

        <div className="flex space-x-4 mb-4 h-[300px]">
          <Tile className="w-1/3 p-4">{alerts}</Tile>
          <Tile className="w-2/3">
            <NetworkMap
              {...{
                latitude: site.latitude,
                longitude: site.longitude,
                chargerDevices,
                batteryDevices,
                meterDevices,
              }}
            />
          </Tile>
        </div>

        <div className="flex space-x-4 mb-4 grow-0">
          <Tile className="w-full">{gridEnergyDraw}</Tile>
          {/* <Tile className="w-1/4">
            <LevelGroupDetails site={site} />
          </Tile> */}
        </div>

        <div className="flex space-x-4 mb-4">
          <Tile className="w-full">{stats}</Tile>
        </div>

        <SelectedSiteLevelProvider>
          <SiteTopology siteId={site.siteId} />
        </SelectedSiteLevelProvider>

        <div className="flex space-x-4 mb-4">
          <Tile className="w-full">{chargerStatus}</Tile>
        </div>
      </div>

      <EditSiteModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        site={site}
        onSave={handleSaveSite}
        onDelete={handleDeleteSite}
      />

      <AddSiteModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAddSite={handleAddSite}
      />

      <DeviceDetailWrapper />
    </div>
  );
};

const SiteDetail = () => {
  const { siteId } = useParams();
  const [simulations, setSimulations] = useState<SimulationResponse[]>([]);

  const { getSimulationsForPlace } = useSimulationsApi();

  useEffect(() => {
    getSimulationsForPlace(siteId, "site").then(setSimulations);
  }, [siteId]);

  return (
    <SelectedTimeRangeProvider>
      <SelectedDeviceProvider>
        <SelectedSimulationProvider simulations={simulations}>
          <PageContent siteId={siteId} />
        </SelectedSimulationProvider>
      </SelectedDeviceProvider>
    </SelectedTimeRangeProvider>
  );
};

export default SiteDetail;

const SiteTopology = ({ siteId }: { siteId: string }) => {
  const { selectedSiteLevel } = useSelectedSiteLevel();
  return (
    <div className="flex space-x-4 mb-4">
      <Tile className="flex-grow">
        <TopologyOverview siteId={siteId} />
      </Tile>
      {selectedSiteLevel && (
        <Tile className="w-1/3">
          <FilteredDevices siteId={siteId} />
        </Tile>
      )}
    </div>
  );
};
