import { useState, useCallback } from 'react';
import { useThingsApi } from "api/ingestion/things";
import { Policy } from '../types/policy';
import { PolicyDocument } from '../types/policy';

interface PolicyState {
  policyDetails: Policy | null;
  selectedPolicy: PolicyDocument | null;
  error: string | null;
  isLoading: boolean;
}

const INITIAL_STATE: PolicyState = {
  policyDetails: null,
  selectedPolicy: null,
  error: null,
  isLoading: false,
};

export const usePolicy = () => {
  const [state, setState] = useState<PolicyState>(INITIAL_STATE);
  const { getDefaultPolicy, updateThingPolicy } = useThingsApi();

  const getResourceValue = useCallback((resource: string, type: 'client' | 'topic' | 'topicfilter'): string => {
    const parts = resource.split(`${type}/`);
    return parts.length > 1 ? parts[1] : '';
  }, []);

  const handleViewPolicy = useCallback(async (
    placeType: string, 
    siteId: string, 
    thingId: string, 
    policyArn: string
  ) => {
    setState(prev => ({ ...prev, isLoading: true, error: null }));
    
    try {
      if (!placeType || !siteId || !thingId) {
        throw new Error('Missing required parameters');
      }

      const policyResponse = await getDefaultPolicy(placeType, siteId, thingId);
      
      if (!policyResponse) {
        throw new Error('No policy found');
      }

      const parsedPolicy: PolicyDocument = typeof policyResponse === 'string' 
        ? JSON.parse(policyResponse) 
        : policyResponse;

      if (!parsedPolicy.Statement) {
        throw new Error('Invalid policy format: missing Statement');
      }
      
      // Transform the policy into the expected format
      const transformedPolicy = {
        connectionEndpoints: parsedPolicy.Statement
          .filter((stmt: any) => 
            stmt.Action === 'iot:Connect' && 
            !stmt.Condition
          )
          .map((stmt: any) => {
            const resource = Array.isArray(stmt.Resource) ? stmt.Resource[0] : stmt.Resource;
            return getResourceValue(resource, 'client');
          })
          .filter(Boolean),
        
        subscribeTopics: parsedPolicy.Statement
          .filter((stmt: any) => 
            (Array.isArray(stmt.Action) && stmt.Action.includes('iot:Subscribe')) ||
            stmt.Action === 'iot:Subscribe'
          )
          .map((stmt: any) => Array.isArray(stmt.Resource) ? stmt.Resource : [stmt.Resource])
          .flat()
          .map((resource: string) => getResourceValue(resource, 'topicfilter'))
          .filter(Boolean),
        
        publishTopics: parsedPolicy.Statement
          .filter((stmt: any) => 
            (Array.isArray(stmt.Action) && stmt.Action.includes('iot:Publish')) ||
            stmt.Action === 'iot:Publish'
          )
          .map((stmt: any) => Array.isArray(stmt.Resource) ? stmt.Resource : [stmt.Resource])
          .flat()
          .map((resource: string) => getResourceValue(resource, 'topic'))
          .filter(Boolean)
      };
      
      setState(prev => ({
        ...prev,
        policyDetails: transformedPolicy,
        selectedPolicy: parsedPolicy,
        isLoading: false,
        error: null,
      }));
    } catch (error) {
      console.error("Error fetching policy details:", error);
      setState(prev => ({
        ...prev,
        error: error,
        isLoading: false,
        policyDetails: null,
        selectedPolicy: null,
      }));
    }
  }, [getDefaultPolicy, getResourceValue]);

  const handleDeleteResource = useCallback(async (
    placeType: string,
    siteId: string,
    thingId: string,
    type: 'connect' | 'subscribe' | 'publish',
    value: string
  ) => {
    setState(prev => ({ ...prev, isLoading: true, error: null }));
  
    try {
      if (!state.selectedPolicy) {
        throw new Error('No policy selected');
      }
  
      const updatedPolicy = JSON.parse(JSON.stringify(state.selectedPolicy));
  
      const anyResource = updatedPolicy.Statement[0]?.Resource;
      if (!anyResource) {
        throw new Error('Invalid policy: no resources found');
      }
  
      const resource = Array.isArray(anyResource) ? anyResource[0] : anyResource;
      const match = resource.match(/arn:aws:iot:([^:]+):([^:]+):/);
      if (!match) {
        throw new Error('Could not determine the correct ARN pattern');
      }
  
      const [, region, account] = match;
      const baseArn = `arn:aws:iot:${region}:${account}`;
  
      switch (type) {
        case 'connect': {
          const connectStmt = updatedPolicy.Statement.find(
            stmt => stmt.Action === 'iot:Connect' && !stmt.Condition
          );
  
          if (connectStmt) {
            if (Array.isArray(connectStmt.Resource)) {
              const resourceToDelete = `${baseArn}:client/${value}`;
              connectStmt.Resource = connectStmt.Resource.filter(
                (r: string) => r !== resourceToDelete
              );
  
              // Remove the statement if no resources left
              if (connectStmt.Resource.length === 0) {
                updatedPolicy.Statement = updatedPolicy.Statement.filter(
                  stmt => stmt !== connectStmt
                );
              }
            }
          }
          break;
        }
  
        case 'subscribe': {
          const subscribeStmt = updatedPolicy.Statement.find(
            stmt => (Array.isArray(stmt.Action) && stmt.Action.includes('iot:Subscribe')) ||
                   stmt.Action === 'iot:Subscribe'
          );
  
          if (subscribeStmt) {
            if (Array.isArray(subscribeStmt.Resource)) {
              const resourceToDelete = `${baseArn}:topicfilter/${value}`;
              subscribeStmt.Resource = subscribeStmt.Resource.filter(
                (r: string) => r !== resourceToDelete
              );
  
              // Remove the statement if no resources left
              if (subscribeStmt.Resource.length === 0) {
                updatedPolicy.Statement = updatedPolicy.Statement.filter(
                  stmt => stmt !== subscribeStmt
                );
              }
            }
          }
          break;
        }
  
        case 'publish': {
          const publishStmt = updatedPolicy.Statement.find(
            stmt => (Array.isArray(stmt.Action) && stmt.Action.includes('iot:Publish')) ||
                   stmt.Action === 'iot:Publish'
          );
  
          if (publishStmt) {
            if (Array.isArray(publishStmt.Resource)) {
              const resourceToDelete = `${baseArn}:topic/${value}`;
              publishStmt.Resource = publishStmt.Resource.filter(
                (r: string) => r !== resourceToDelete
              );
  
              // Also remove iot:Receive if it exists
              if (Array.isArray(publishStmt.Action)) {
                const receiveIndex = publishStmt.Action.indexOf('iot:Receive');
                if (receiveIndex !== -1) {
                  publishStmt.Action.splice(receiveIndex, 1);
                }
              }
  
              // Remove the statement if no resources left
              if (publishStmt.Resource.length === 0) {
                updatedPolicy.Statement = updatedPolicy.Statement.filter(
                  stmt => stmt !== publishStmt
                );
              }
            }
          }
          break;
        }
  
        default:
          throw new Error(`Unsupported resource type: ${type}`);
      }
  
      // Remove empty statements
      updatedPolicy.Statement = updatedPolicy.Statement.filter(
        stmt => Array.isArray(stmt.Resource) ? stmt.Resource.length > 0 : stmt.Resource
      );
  
      const formattedPolicyString = `"${JSON.stringify(updatedPolicy, null, 2)
        .replace(/\n/g, '\\n')
        .replace(/"/g, '\\"')}"`;
  
      const result = await updateThingPolicy(placeType, siteId, thingId, formattedPolicyString);
  
      if (!result.success) {
        throw new Error(result.error || 'Failed to update policy');
      }
  
      // Refresh the policy view
      await handleViewPolicy(
        placeType,
        siteId,
        thingId,
        updatedPolicy.Statement[0]?.Resource[0] || ''
      );
  
      setState(prev => ({ ...prev, isLoading: false, error: null }));
      return true;
  
    } catch (error) {
      console.error("Error deleting resource:", error);
  
      const errorMessage = error instanceof Error 
        ? error.message 
        : "Failed to delete resource. Please try again.";
  
      setState(prev => ({
        ...prev,
        error: errorMessage,
        isLoading: false,
      }));
  
      return false;
    }
  }, [state.selectedPolicy, updateThingPolicy, handleViewPolicy]);

  const clearError = useCallback(() => {
    setState(prev => ({ ...prev, error: null }));
  }, []);

  return {
    ...state,
    setPolicyDetails: useCallback((details: Policy | null) => {
      setState(prev => ({ ...prev, policyDetails: details }));
    }, []),
    setSelectedPolicy: useCallback((policy: PolicyDocument | null) => {
      setState(prev => ({ ...prev, selectedPolicy: policy }));
    }, []),
    handleViewPolicy,
    handleDeleteResource,
    handleUpdatePolicy: useCallback(async (
      placeType: string,
      siteId: string,
      thingId: string,
      policyDocument: PolicyDocument
    ) => {
      setState(prev => ({ ...prev, isLoading: true, error: null }));
      
      try {
        const result = await updateThingPolicy(placeType, siteId, thingId, policyDocument);
        
        if (!result.success) {
          throw new Error(result.error || 'Failed to update policy');
        }

        await handleViewPolicy(
          placeType,
          siteId,
          thingId,
          policyDocument.Statement[0]?.Resource[0] || ''
        );
        
        return true;
      } catch (error) {
        console.error("Error updating policy:", error);
        
        setState(prev => ({
          ...prev,
          error: "Failed to update policy. Please try again.",
          isLoading: false,
        }));
        
        return false;
      }
    }, [handleViewPolicy, updateThingPolicy]),
    clearError,
  };
};