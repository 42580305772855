import { useThingsApi } from "api/ingestion/things";

import React, { useState } from "react";


const AddDeviceModal = ({ isOpen, onClose, onDeviceAdded, sites, deviceTypes }) => {
  const [formData, setFormData] = useState({
    thingName: '',
    thingType: deviceTypes[0],
    thingDescription: '',
    model: '',
    isSimulated: false,
    longitude: '',
    latitude: '',
    altitude: '',
    levelId: '',
    siteId: '',
  });

  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const { addDevice } = useThingsApi();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!formData.thingName) {
      errors.thingName = 'Thing Name is required';
      valid = false;
    }
    if (!formData.thingType) {
      errors.thingType = 'Thing Type is required';
      valid = false;
    }
    if (!formData.siteId) {
      errors.siteId = 'Site is required';
      valid = false;
    }
    if (!formData.levelId) {
      errors.levelId = 'Level ID is required';
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleAddDevice = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    addDevice(formData.siteId, formData)
      .then((result) => {
        if (result.success) {
          onDeviceAdded();
          handleCloseModal();
        } else {
          setErrorMessage(result.error.message);
        }
      });
  };

  const handleCloseModal = () => {
    onClose();
    setFormData({
      thingName: '',
      thingType: '',
      thingDescription: '',
      model: '',
      isSimulated: false,
      longitude: '',
      latitude: '',
      altitude: '',
      levelId: '',
      siteId: '',
    });
    setErrors({});
    setErrorMessage('');
  };

  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl mb-4">Add Device</h2>
        <form onSubmit={handleAddDevice}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="siteId">
              Site
            </label>
            <select
              name="siteId"
              id="siteId"
              value={formData.siteId}
              onChange={handleChange}
              className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.siteId ? 'border-red-500' : ''
              }`}
            >
              <option value="">Select a site</option>
              {sites.map((site) => (
                <option key={site.siteId} value={site.siteId}>
                  {site.siteName}
                </option>
              ))}
            </select>
            {errors.siteId && <p className="text-red-500 text-xs italic">{errors.siteId}</p>}
          </div>

          <div className="grid grid-cols-1 gap-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="thingType">
                Type
              </label>
              <select
                name="thingType"
                value={formData.thingType}
                onChange={handleChange}
                required
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.thingType ? 'border-red-500' : ''
                }`}
              >
                {deviceTypes.map((type, index) => (
                  <option key={index} value={type}>{type}</option>
                ))}
              </select>
              {errors.thingType && <p className="text-red-500 text-xs italic">{errors.thingType}</p>}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="thingName">
                Name
              </label>
              <input
                type="text"
                name="thingName"
                id="thingName"
                value={formData.thingName}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.thingName ? 'border-red-500' : ''
                }`}
              />
              {errors.thingName && <p className="text-red-500 text-xs italic">{errors.thingName}</p>}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="thingDescription">
                Description
              </label>
              <input
                type="text"
                name="thingDescription"
                id="thingDescription"
                value={formData.thingDescription}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.thingDescription ? 'border-red-500' : ''
                }`}
              />
              {errors.thingDescription && <p className="text-red-500 text-xs italic">{errors.thingDescription}</p>}
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="latitude">
                Latitude
              </label>
              <input
                type="number"
                name="latitude"
                id="latitude"
                value={formData.latitude}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.latitude ? 'border-red-500' : ''
                }`}
              />
              {errors.latitude && <p className="text-red-500 text-xs italic">{errors.latitude}</p>}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="longitude">
                Longitude
              </label>
              <input
                type="number"
                name="longitude"
                id="longitude"
                value={formData.longitude}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.longitude ? 'border-red-500' : ''
                }`}
              />
              {errors.longitude && <p className="text-red-500 text-xs italic">{errors.longitude}</p>}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="altitude">
                Altitude
              </label>
              <input
                type="number"
                name="altitude"
                id="altitude"
                value={formData.altitude}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.altitude ? 'border-red-500' : ''
                }`}
              />
              {errors.altitude && <p className="text-red-500 text-xs italic">{errors.altitude}</p>}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="model">
              Model
            </label>
            <input
              type="text"
              name="model"
              id="model"
              value={formData.model}
              onChange={handleChange}
              className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.model ? 'border-red-500' : ''
              }`}
            />
            {errors.model && <p className="text-red-500 text-xs italic">{errors.model}</p>}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="levelId">
              Level ID
            </label>
            <input
              type="text"
              name="levelId"
              id="levelId"
              value={formData.levelId}
              onChange={handleChange}
              className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.levelId ? 'border-red-500' : ''
              }`}
            />
            {errors.levelId && <p className="text-red-500 text-xs italic">{errors.levelId}</p>}
          </div>

          <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            name="isSimulated"
            id="isSimulated"
            checked={formData.isSimulated}
            onChange={handleChange}
            className="form-checkbox h-5 w-5 text-gray-600"
          />
          <label className="ml-2 block text-gray-700 text-sm font-bold" htmlFor="isSimulated">
            Simulated
          </label>
          </div>

          {errorMessage && (
            <p className="text-red-500 text-xs italic mb-4">{errorMessage}</p>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="submit"
              className="px-3.5 py-2 rounded-full border border-space80 bg-blue90 justify-end items-center gap-1 cursor-pointer flex"
            >
              Add Device
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-3.5 py-2 rounded-full border border-space80 justify-end items-center gap-1 cursor-pointer flex"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default AddDeviceModal;