import { useState } from 'react';

interface NewPolicyEntryModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAdd: (type: EntryType, value: string) => Promise<void>;
    currentPolicy: PolicyDocument;
    onUpdate: (policy: PolicyDocument) => Promise<void>;
  }

  const validateMqttTopic = (topic: string, isFilter: boolean = false): { isValid: boolean; error: string | null } => {
    // Empty check
    if (!topic.trim()) {
      return { isValid: false, error: 'Topic cannot be empty' };
    }
  
    // Length check (250 bytes max)
    if (new TextEncoder().encode(topic).length > 250) {
      return { isValid: false, error: 'Topic exceeds maximum length of 250 bytes' };
    }
  
    // Level count check (128 levels max)
    const levels = topic.split('/');
    if (levels.length > 128) {
      return { isValid: false, error: 'Topic cannot have more than 128 levels' };
    }
  
    // Special characters check for publish topics (non-filters)
    if (!isFilter) {
      if (topic.includes('+') || topic.includes('#')) {
        return { isValid: false, error: 'Publish topics cannot contain wildcards (+ or #)' };
      }
    }
  
    // Validate filter wildcards
    if (isFilter) {
      // '#' can only appear at the end and must be preceded by a '/'
      const hashIndex = topic.indexOf('#');
      if (hashIndex !== -1) {
        if (hashIndex !== topic.length - 1 || (hashIndex > 0 && topic[hashIndex - 1] !== '/')) {
          return { isValid: false, error: '# wildcard must appear at the end and be preceded by /' };
        }
      }
  
      // '+' must be a full level (surrounded by '/' or at start/end)
      const plusPositions = topic.split('').reduce((acc: number[], char, i) => {
        if (char === '+') acc.push(i);
        return acc;
      }, []);
  
      for (const pos of plusPositions) {
        const prevChar = pos > 0 ? topic[pos - 1] : '/';
        const nextChar = pos < topic.length - 1 ? topic[pos + 1] : '/';
        if (prevChar !== '/' || (nextChar !== '/' && nextChar !== undefined)) {
          return { isValid: false, error: '+ wildcard must occupy an entire level' };
        }
      }
    }
  
    // Check for starting $
    if (topic.startsWith('$') && !topic.startsWith('$share/')) {
      return { isValid: false, error: 'Topics starting with $ are reserved for system use' };
    }
  
    return { isValid: true, error: null };
  };

export const NewPolicyEntryModal: React.FC<NewPolicyEntryModalProps> = ({
  isOpen,
  onClose,
  currentPolicy,
  onUpdate,
}) => {
  const [entryType, setEntryType] = useState<EntryType>('connection');
  const [value, setValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<string | null>(null);

  if (!isOpen) return null;

  const formatPolicyString = (policy: any): string => {
    const indentedJson = JSON.stringify(policy, null, 2);
    // Replace all newlines with \n and escape double quotes
    return indentedJson.split('\n').join('\\n').replace(/"/g, '\\"');
  };

  const handleInputChange = (inputValue: string) => {
    setValue(inputValue);
    
    if (entryType === 'connection') {
      // Client ID validation - simpler rules
      if (inputValue.includes('/') || inputValue.includes('+') || inputValue.includes('#')) {
        setValidationError('Client ID cannot contain /, +, or # characters');
      } else if (inputValue.length > 23) { // Common MQTT client ID length limit
        setValidationError('Client ID should not exceed 23 characters');
      } else {
        setValidationError(null);
      }
    } else {
      // Topic validation for publish/subscribe
      const isFilter = entryType === 'subscribe';
      const { isValid, error } = validateMqttTopic(inputValue, isFilter);
      setValidationError(error);
    }
  };


  const extractArnBase = (statement: any): string => {
    let resource = '';
    if (Array.isArray(statement.Resource) && statement.Resource.length > 0) {
      resource = statement.Resource[0];
    } else if (typeof statement.Resource === 'string') {
      resource = statement.Resource;
    }

    // Extract the base ARN pattern
    const match = resource.match(/arn:aws:iot:([^:]+):([^:]+):/);
    if (match) {
      return `arn:aws:iot:${match[1]}:${match[2]}`;
    }
    return '';
  };

  const handleAdd = async () => {
    if (validationError) {
      return;
    }
    try {
      setIsSubmitting(true);
      setError(null);

      const parsedPolicy = typeof currentPolicy === 'string' 
        ? JSON.parse(currentPolicy) 
        : currentPolicy;

      // Make a deep copy of the parsed policy
      const updatedPolicy = JSON.parse(JSON.stringify(parsedPolicy));

      const baseArn = extractArnBase(updatedPolicy.Statement[0]);
      if (!baseArn) {
        throw new Error('Could not determine the correct ARN pattern');
      }

      switch (entryType) {
        case 'connection': {
          let connectStmt = updatedPolicy.Statement.find(
            stmt => Array.isArray(stmt.Action) && 
                   stmt.Action.includes('iot:Connect') &&
                   !stmt.Condition
          );

          if (!connectStmt) {
            connectStmt = updatedPolicy.Statement.find(
              stmt => stmt.Action === 'iot:Connect' &&
                     !stmt.Condition
            );
          }
          
          const resource = `${baseArn}:client/${value}`;
          
          if (connectStmt) {
            if (!Array.isArray(connectStmt.Action)) {
              connectStmt.Action = [connectStmt.Action];
            }
            if (!Array.isArray(connectStmt.Resource)) {
              connectStmt.Resource = [connectStmt.Resource];
            }
            if (!connectStmt.Resource.includes(resource)) {
              connectStmt.Resource.push(resource);
            }
          }
          break;
        }

        case 'subscribe': {
          let subscribeStmt = updatedPolicy.Statement.find(
            stmt => Array.isArray(stmt.Action) && 
                   stmt.Action.includes('iot:Subscribe')
          );

          if (!subscribeStmt) {
            subscribeStmt = updatedPolicy.Statement.find(
              stmt => stmt.Action === 'iot:Subscribe'
            );
          }

          const resource = `${baseArn}:topicfilter/${value}`;

          if (subscribeStmt) {
            if (!Array.isArray(subscribeStmt.Action)) {
              subscribeStmt.Action = [subscribeStmt.Action];
            }
            if (!Array.isArray(subscribeStmt.Resource)) {
              subscribeStmt.Resource = [subscribeStmt.Resource];
            }
            if (!subscribeStmt.Resource.includes(resource)) {
              subscribeStmt.Resource.push(resource);
            }
          }
          break;
        }

        case 'publish': {
          let publishStmt = updatedPolicy.Statement.find(
            stmt => Array.isArray(stmt.Action) && 
                   stmt.Action.includes('iot:Publish')
          );

          if (!publishStmt) {
            publishStmt = updatedPolicy.Statement.find(
              stmt => stmt.Action === 'iot:Publish'
            );
          }
          
          const resource = `${baseArn}:topic/${value}`;
          
          if (publishStmt) {
            if (!Array.isArray(publishStmt.Action)) {
              publishStmt.Action = ['iot:Publish', 'iot:Receive'];
            } else if (!publishStmt.Action.includes('iot:Receive')) {
              publishStmt.Action.push('iot:Receive');
            }
            if (!Array.isArray(publishStmt.Resource)) {
              publishStmt.Resource = [publishStmt.Resource];
            }
            if (!publishStmt.Resource.includes(resource)) {
              publishStmt.Resource.push(resource);
            }
          }
          break;
        }
      }
      const formattedPolicy = `"${formatPolicyString(updatedPolicy)}"`;
    await onUpdate(formattedPolicy);
      setValue('');
      onClose();
    } catch (error) {
      console.error('Error adding new entry:', error);
      setError('Failed to add new entry. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-[9999]"
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-lg p-6 w-full max-w-lg mx-4"
        onClick={e => e.stopPropagation()}
      >
        <h2 className="text-xl font-medium mb-6">Add New Entry</h2>

        {error && (
          <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-700 rounded">
            {error}
          </div>
        )}

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Entry Type
            </label>
            <select
              value={entryType}
              onChange={(e) => {
                setEntryType(e.target.value as EntryType);
                setValue('');
                setValidationError(null);
              }}
              className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="connection">Connect</option>
              <option value="subscribe">Subscribe</option>
              <option value="publish">Publish</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {entryType === 'connection' ? 'Client ID' : 'Topic'}
            </label>
            <input
              type="text"
              value={value}
              onChange={(e) => handleInputChange(e.target.value)}
              placeholder={entryType === 'connection' 
                ? 'Enter client ID' 
                : entryType === 'subscribe' 
                  ? 'Enter topic filter (can use + and # wildcards)' 
                  : 'Enter topic path'
              }
              className={`w-full rounded-md border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                validationError ? 'border-red-300' : 'border-gray-300'
              }`}
            />
            {validationError && (
              <p className="mt-1 text-sm text-red-600">{validationError}</p>
            )}
          </div>

          <p>
          Warning text about generating a new certificate to replace the old one orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip 
          </p>
        </div>

        <div className="mt-6 flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            onClick={handleAdd}
            disabled={!value.trim() || isSubmitting || !!validationError}
            className="px-4 py-2 bg-blue-700 text-white rounded hover:bg-blue-800 disabled:opacity-50"
          >
            {isSubmitting ? 'Adding...' : 'Create Entry +'}
          </button>
        </div>
      </div>
    </div>
  );
};