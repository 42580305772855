import { useState, useEffect } from 'react';
import { useThingsApi } from "api/ingestion/things.ts";
import { Certificate, CertificateDetails } from '../types/certificate';

export const useCertificates = (placeType: string, siteId: string, thingId: string) => {
    const [certificates, setCertificates] = useState<Certificate[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [certificateDetails, setCertificateDetails] = useState<CertificateDetails | null>(null);

  const {
    getThingCertificates,
    getCertificatePolicies,
    createDefaultPolicyCertificate,
  } = useThingsApi();

  const fetchCertificates = async () => {
    try {
      setIsLoading(true);
      const certIds = await getThingCertificates(placeType, siteId, thingId);

      if (certIds.length > 0) {
        const certsWithPolicies = await Promise.all(
          certIds.map(async (certId) => {
            const policies = await getCertificatePolicies(placeType, siteId, thingId, certId);
            // Generate random expiration date between now and 1 year from now
            const randomDate = new Date();
            randomDate.setDate(randomDate.getDate() + Math.floor(Math.random() * 365));

            return {
              id: certId,
              policy: policies[0], // Take first policy since there's only one
              expirationDate: randomDate.toLocaleString(),
            };
          })
        );
        setCertificates(certsWithPolicies);
      } else {
        setCertificates([]);
      }
    } catch (error) {
      console.error("Error fetching certificates:", error);
      setError("An error occurred while fetching certificates. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateCertificate = async () => {
    try {
      setIsGenerating(true);
      setError(null);

      const result = await createDefaultPolicyCertificate(placeType, siteId, thingId);
      if (result.success) {
        let certResponse;
        try {
          certResponse = typeof result.message === 'string' 
            ? JSON.parse(result.message) 
            : result.message;

          setCertificateDetails({
            certificateId: certResponse.CertificateId,
            certificatePem: certResponse.CertificatePem,
            privateKey: certResponse.KeyPair.PrivateKey,
            publicKey: certResponse.KeyPair.PublicKey
          });
          await fetchCertificates();
        } catch (parseError) {
          console.error("Error parsing certificate response:", parseError);
          setError("Failed to process certificate data. Please try again.");
        }
      } else {
        setError(result.error || "Failed to generate certificate. Please try again.");
      }
    } catch (error) {
      console.error("Error generating certificate:", error);
      setError("Failed to generate certificate. Please try again.");
    } finally {
      setIsGenerating(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    setError(null);

    const loadData = async () => {
      if (!mounted) return;
      await fetchCertificates();
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, [placeType, siteId, thingId]);

  return {
    certificates,
    isLoading,
    error,
    certificateDetails,
    setCertificateDetails,
    isGenerating,
    handleCreateCertificate,
    refreshCertificates: fetchCertificates,
  };
};